declare module 'o365.pwa.declaration.shared.dexie.objectStores.User.d.ts' {
    export class User {
        static objectStoreDexieSchema: string;

        id: number;
        userSession: any;

        constructor(id: number, userSession: any);

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<User | null>;
    }
}

export {};
